import React from 'react'

import { money } from '@guiker/money'
import { mainPathBuilder } from '@guiker/my-investment-shared'
import { PayoutMethodStatus, transferTypeChecker } from '@guiker/payment-shared'
import { Payout as PayoutComponent } from '@guiker/payout-app-components'
import {
  isPaidOffline,
  Payout as PayoutEntity,
  payoutMethodInfoBuilder,
  Transfer,
  TransferProjection,
  TransferStatus,
  WithPayoutMethod,
  WithTransfers,
} from '@guiker/payout-shared'
import {
  ColumnConfig,
  FetcherArg,
  Flex,
  H3,
  P,
  PaginatedTable,
  PSmall,
  PSmaller,
  TextButton,
  useDateFormatter,
  useNavigate,
  useParams,
} from '@guiker/react-framework'
import { DataAndMeta, Paginate } from '@guiker/shared-framework'

import { useAuthenticatedPayoutApiClient, usePayoutContext } from '../../hooks'
import { useT } from '../../i18n'

type Payout = WithTransfers<WithPayoutMethod<PayoutEntity>>

export type PayoutTableFetcher = (
  args: FetcherArg<never, never, never, { payoutMethodIds?: string[] }> & {
    payoutMethodIds?: string[]
  },
) => Promise<DataAndMeta<Payout[]>>

const useGetColumns = (): ColumnConfig<Payout, Transfer>[] => {
  const { tBase, tMain } = useT({ screenName: 'components.listPayouts' })
  const { formatDate } = useDateFormatter()
  const navigate = useNavigate()

  const hasScopeData = (transfer: TransferProjection) => {
    return transferTypeChecker.isInvoice(transfer) && !!transfer.scope.data
  }

  return [
    {
      name: 'payoutMethod',
      size: 3,
      label: tMain(`account`),
      renderValue: (payout) => {
        return (
          <PayoutComponent.PayoutMethodLabel payoutMethod={payout.payoutMethod} isPaidOffline={isPaidOffline(payout)} />
        )
      },
      collapsibleOptions: {
        verticalAlign: 'top',
      },
      collapsibleValues: (payout) => payout.transfers,
      renderCollapsibleValue: ({ info: { payerName, payerEmailAddress, description, label } }: Transfer) => {
        const secondaryInfo = { label, description }

        if (secondaryInfo.description.indexOf('/') !== -1) {
          const [firstLine, secondLine] = secondaryInfo.description.split('/')
          const [city] = secondLine?.split(',') || [secondLine]

          secondaryInfo.description = `${firstLine}, ${city}`
        }

        return [
          <Flex mb={1} flexDirection='column'>
            <PSmall>{payerName}</PSmall>
            <PSmaller>{payerEmailAddress}</PSmaller>
          </Flex>,
          <Flex flexDirection='column'>
            <PSmall>{secondaryInfo.description}</PSmall>
            <PSmaller>{secondaryInfo.label}</PSmaller>
          </Flex>,
        ]
      },
    },
    {
      name: 'status',
      size: 1,
      label: tMain(`status.label`),
      renderValue: (payout) => {
        return <PayoutComponent.PayoutStatusChip payout={payout} />
      },
      collapsibleOptions: {
        verticalAlign: 'top',
      },
      collapsibleValues: (payout) => payout.transfers,
      renderCollapsibleValue: (transfer: Transfer) => <PayoutComponent.TransferStatusChip transfer={transfer} />,
    },
    {
      name: 'createdAt',
      label: tMain('initiateDate'),
      size: 1,
      renderValue: (payout) => {
        return formatDate(payout.createdAt)
      },
      collapsibleOptions: {
        verticalAlign: 'top',
      },
      collapsibleValues: (payout) => payout.transfers,
      renderCollapsibleValue: ({ createdAt }: Transfer) => formatDate(createdAt),
    },
    {
      label: tMain(`amount`),
      headerOptions: {
        textAlign: 'right',
      },
      name: 'amount',
      size: 1,
      renderValue: (payout) => {
        return (
          <P textAlign='right' mb={0}>
            {money.fromAmount(payout.amount, payout.currency).toString(true)}
          </P>
        )
      },
      collapsibleOptions: {
        verticalAlign: 'top',
      },
      collapsibleValues: (payout) => payout.transfers,
      renderCollapsibleValue: (transfer) => {
        const isNegative = transfer.status === TransferStatus.REVERSED
        const amount = money
          .fromAmount(transfer.amount, transfer.parent?.currency)
          .toString({ currencySymbol: 'onlySymbol', isNegative })

        return (
          <PSmall textAlign='right' mb={0}>
            {amount}
          </PSmall>
        )
      },
    },
    {
      label: '',
      name: 'action',
      size: 0.5,
      sortable: false,
      headerOptions: { textAlign: 'left' },
      renderValue: ({ id }) => {
        const path = mainPathBuilder.root.payouts.byId(id).path
        return (
          <TextButton size='small' onClick={() => navigate(path)}>
            <PSmall mb={0}>{tBase('actions.view')}</PSmall>
          </TextButton>
        )
      },
      collapsibleOptions: {
        verticalAlign: 'top',
      },
      collapsibleValues: (payout) => payout.transfers,
      renderCollapsibleValue: (transfer) => {
        if (!hasScopeData(transfer)) return <></>
        const id = transfer.scope.id
        const path = mainPathBuilder.root.rentalTenancy.tenantInstalments.byId(id).path
        return (
          <TextButton size='small' onClick={() => navigate(path)}>
            <PSmaller mb={0}>{tBase('actions.view')}</PSmaller>
          </TextButton>
        )
      },
    },
  ]
}

const PayoutTable: React.FC = () => {
  const { tMain } = useT({ screenName: 'components.listPayouts' })
  const columns = useGetColumns()
  const { id } = useParams()
  const { payoutMethods } = usePayoutContext()
  const apiClient = useAuthenticatedPayoutApiClient()

  const fetcher: PayoutTableFetcher = async (params) => {
    const { payoutMethodIds, filters, ...paginate } = params

    return apiClient.readAllPayouts({
      queryParams: {
        payoutMethodIds: !!id ? [id] : payoutMethodIds,
        ...(paginate ?? ({} as Paginate)),
      },
    })
  }

  return (
    <Flex flexDirection='column'>
      <H3>{tMain('title')}</H3>
      <PaginatedTable
        fetcher={fetcher}
        queryKey='readAllPayouts'
        columns={columns}
        emptyState={{
          label: tMain('emptyState.label'),
          description: tMain('emptyState.description'),
        }}
        filters={{
          ...(!id
            ? {
                payoutMethodIds: {
                  type: 'FilterMultiSelect',
                  name: 'payoutMethodIds',
                  label: tMain('filters.payoutMethods.title'),
                  inputLabel: tMain('filters.payoutMethods.label'),
                  options: payoutMethods
                    ? payoutMethods
                        .filter((payoutMethod) =>
                          [PayoutMethodStatus.ENABLED, PayoutMethodStatus.DISABLED].includes(payoutMethod.status),
                        )
                        .map((payoutMethod) => ({
                          value: payoutMethod.id,
                          label: payoutMethodInfoBuilder(payoutMethod)?.getDisplay(),
                        }))
                    : [],
                },
              }
            : {}),
        }}
        isRowCollapsible={true}
        collapsibleOptions={{
          isOpen: false,
        }}
      />
    </Flex>
  )
}

export { PayoutTable }
