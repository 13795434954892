import React from 'react'

import { PayoutMethod, PayoutMethodStatus } from '@guiker/payout-shared'

import { useT } from '../../i18n'
import { Chip, StatusKey } from '..'

type Prop = {
  payoutMethod: PayoutMethod
}

const getColorAndLabel = (status: PayoutMethodStatus): { color: StatusKey; labelKey: string } => {
  switch (status) {
    case PayoutMethodStatus.CREATED:
      return {
        color: 'info',
        labelKey: 'created',
      }
    case PayoutMethodStatus.PENDING_SUBMISSION:
    case PayoutMethodStatus.SUBMITTED:
    case PayoutMethodStatus.NEED_MORE_INFORMATION:
      return {
        color: 'info',
        labelKey: 'processing',
      }
    case PayoutMethodStatus.ENABLED:
      return {
        color: 'success',
        labelKey: 'enabled',
      }
    case PayoutMethodStatus.CHANGE_REQUESTED:
      return {
        color: 'alert',
        labelKey: 'changeRequested',
      }
    case PayoutMethodStatus.REJECTED:
      return {
        color: 'alert',
        labelKey: 'rejected',
      }
    case PayoutMethodStatus.DISABLED:
      return {
        color: 'info',
        labelKey: 'disabled',
      }
  }
}

const PayoutMethodStatusChip: React.FC<Prop> = ({ payoutMethod }) => {
  const { tMain } = useT({ screenName: 'components.listPayoutMethods' })
  const { color, labelKey } = getColorAndLabel(payoutMethod?.status)

  if (!payoutMethod) return

  return (
    <Chip color={color} size='small'>
      {tMain(`status.${labelKey}`)}
    </Chip>
  )
}

export { PayoutMethodStatusChip }
