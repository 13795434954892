import React from 'react'

import { Location } from '@guiker/base-entity'
import { Paginate } from '@guiker/paginate'
import { Flex, H3, Link, SecondaryButton, theme, useMediaQuery, useTranslation } from '@guiker/react-framework'
import { CarouselListings, ListingCard, PaginatedListingsGrid } from '@guiker/rental-listing-components'

import { useRecommendedListingApiClient } from '../../hooks'

type SimilarListingsProps = {
  listingId: string
  location: Location
}

export const SimilarListings: React.FC<SimilarListingsProps> = ({ listingId, location }) => {
  const { t } = useTranslation(['main-rentalListing'])
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const apiClient = useRecommendedListingApiClient()

  const fetcher = ({ page, perPage }: { page: number; perPage: number }) =>
    apiClient.readAllRecommendedListingsByListingId({
      pathParams: { listingId },
      queryParams: {
        page: page || 1,
        perPage,
      } as Paginate,
    })

  const common = {
    cacheKey: ['fetchRecommendedListing', location.city.slug],
    fetcher,
  }

  return (
    <Flex flexDirection='column' gap={4}>
      <H3 mb={0}>{t('similarListings')}</H3>
      {isMobile ? (
        <CarouselListings {...common} CellComponent={({ data }) => <ListingCard listing={data} />} />
      ) : (
        <PaginatedListingsGrid {...common} withPagination={false} rows={1} />
      )}
      <Flex justifyContent='center'>
        <Link
          isExternalLink
          to={`/listings?city=${location.city.slug}&latitude=${location.city.coordinates.latitude}&longitude=${location.city.coordinates.longitude}`}
        >
          <SecondaryButton>{t('actions.seeMore')}</SecondaryButton>
        </Link>
      </Flex>
    </Flex>
  )
}
