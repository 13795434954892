import React, { useEffect } from 'react'

import { ConductFor } from '@guiker/background-check-shared'
import { BookingApplication, HistoryModal } from '@guiker/booking-components'
import { ApplicationStatus, BookingActions, isApplicationApproved } from '@guiker/booking-shared'
import { useApplicationComponentsContext } from '@guiker/tenant-application-components'

import { PageLayout } from '../../components'
import { useBookingApplicationContext, useBookingContext, useNavigate, useTranslation } from '../../hooks'
import { useBookingApiClient } from '../../lib'

const ReviewableStatus = [
  ApplicationStatus.SUBMITTED,
  ApplicationStatus.ACCEPTED,
  ApplicationStatus.REJECTED,
  ApplicationStatus.CHANGE_REQUESTED,
]

const UnitManagerViewBookingApplicationScreen: React.FC = () => {
  const { t } = useTranslation(['common', 'main-bookingApplicant', 'main-tenantApplication'])
  const { isLoading, bookingApplication, setBookingApplication } = useBookingApplicationContext()
  const { setRequestBackgroundCheck, setIsApplicationApproved, setBackgroundCheckError } =
    useApplicationComponentsContext()
  const { bookingId, booking, bookingChat, can } = useBookingContext()
  const apiClient = useBookingApiClient()
  const navigate = useNavigate()
  const returnToChat = booking.applicants.length === 1

  const canRequestBackgroundCheck = can(BookingActions.RequestBackgroundCheck, { adminOnly: true })

  const handleActionSuccess = (status: ApplicationStatus) => {
    setIsApplicationApproved(isApplicationApproved({ ...bookingApplication, status }))
    setBookingApplication({ ...bookingApplication, status })
    returnToChat ? bookingChat.navigate() : navigate('../../applications')
  }

  if (!bookingApplication) {
    return <></>
  }

  const handleRequestBackgroundCheck = async (payload: { conductCheckFor?: ConductFor }) => {
    const pathParams = { bookingId, applicantUserId: bookingApplication?.userId }

    if (payload) {
      setBackgroundCheckError(undefined)
      const { conductCheckFor } = payload
      if (conductCheckFor === 'applicant') {
        await apiClient.requestBackgroundCheck({ pathParams }).catch((error) => {
          if (error.meta?.meta?.errorCode === 'CertnScreenApplicantFieldError') {
            setBackgroundCheckError(error.meta.meta.meta)
          }
        })
      } else if (conductCheckFor === 'guarantor') {
        await apiClient.requestGuarantorBackgroundCheck({ pathParams }).catch((error) => {
          if (error.meta?.meta?.errorCode === 'CertnScreenApplicantFieldError') {
            setBackgroundCheckError(error.meta.meta.meta)
          }
        })
      }
    }
  }

  useEffect(() => {
    setIsApplicationApproved(isApplicationApproved(bookingApplication))
    canRequestBackgroundCheck
      ? setRequestBackgroundCheck(() => handleRequestBackgroundCheck)
      : setRequestBackgroundCheck(undefined)
  }, [canRequestBackgroundCheck])

  return (
    <PageLayout
      maxWidth={900}
      hasButtonContainer
      noBreadcrumpSpacer
      forceTakeover
      takeoverProps={{
        onClose: bookingChat.navigate,
        onBack: booking.applicants.length === 1 ? bookingChat.navigate : () => navigate('../../applications'),
        title: t('main-tenantApplication:screens.createTenantApplication.title'),
        subtitle:
          ReviewableStatus.includes(bookingApplication?.status) &&
          t(`main-bookingApplicant:statusSubHeader.${bookingApplication?.status}`),
      }}
    >
      <HistoryModal bookingId={booking?.id} applicationId={bookingApplication?.userId} />
      <BookingApplication
        bookingApplication={bookingApplication}
        bookingId={bookingId}
        isLoading={isLoading}
        onActionSuccess={handleActionSuccess}
      />
    </PageLayout>
  )
}

export { UnitManagerViewBookingApplicationScreen }
