import { mainPathBuilder } from '@guiker/my-investment-shared'
import { ListingUnit } from '@guiker/rental-listing-shared'
import { Item, MapperOverride } from '@guiker/search-components'
import { SearchableEntities, SearchResult } from '@guiker/search-shared'

import { useT } from '../i18n'

/** @todo deprecated: update file when new rental-listing is fully implemented */
const mapRentalListingUnit = (listingUnit: ListingUnit): Partial<Item> => {
  return {
    url: mainPathBuilder.root.rentalDashboard.listings
      .byListingId(listingUnit.listingId)
      .units.byInventoryId(listingUnit.id).path,
    status: null,
  }
}

const mapRentalListing = (listing: SearchResult<SearchableEntities.LISTING>['results'][0]): Partial<Item> => {
  return {
    url: mainPathBuilder.root.rentalDashboard.listings.byListingId(listing.id).path,
    status: null,
  }
}

export const useGetMapperOverride = (): MapperOverride => {
  const { tMain } = useT({ screenName: 'components.monthlyPerformanceSearchBar' })

  return {
    listing: {
      label: tMain('listing'),
      mapper: mapRentalListing,
    },
    listingUnit: {
      label: tMain('listingUnit'),
      mapper: mapRentalListingUnit,
    },
  }
}
